import { createRoot } from 'react-dom/client';
import { registerLicense } from '@syncfusion/ej2-base';
import registerServiceWorker from './registerServiceWorker';
//import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
//import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
//import { polyfill } from 'es6-promise'; polyfill();
// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Qd0BiWn1fcnZSQGVV');

const root = createRoot(
        document.querySelector('#root') as HTMLElement
      );


        root.render(
                <Provider store={store}>

                                <App />   

                </Provider>
              );
              
registerServiceWorker();